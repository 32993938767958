$default: #007bff;
$default_dark: #0562c7;

$success: #28a745;
$success_dark: #1f8838;

$danger: #dc3545;
$danger_dark: #bd1120;

$info: #17a2b8;
$info_dark: #138b9e;

$warning: #eab000;
$warning_dark: #ce9c09;

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: #007bff;
$default_timer_filler: #fff;

$success_timer: #28a745;
$success_timer_filler: #fff;

$danger_timer: #dc3545;
$danger_timer_filler: #fff;

$info_timer: #17a2b8;
$info_timer_filler: #fff;

$warning_timer: #eab000;
$warning_timer_filler: #fff;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;